export default {
  pageNotFoundMessage: 'We didnt find the website, is the URL correct?',
  brandingMessage: 'by Code Yellow',
  flowmastersBranding: 'Supported by FlowMasters',
  navisionIntegration: {
    field: {
      company: {label: 'Company'},
      manageEndpointsHeader: {label: 'Endpoints'},
      item: {label: 'Items'},
      customer: {label: 'Customers'},
      jobs: {label: 'Jobs'},
      salesOrder: {label: 'Sales Order'},
      purchaseOrder: {label: 'Purchase Order'},
      productionOrder: {label: 'Production Order'},
    },
    purchaseOrderLine: {
      field: {
        requestedReceiptDate: 'Requested Date',
        promisedReceiptDate: 'Promised Date',
      }
    },
    overview: {
      syncButton: 'Sync'
    }
  },
  nav: {
    main: {
      operations: 'Operations',
      production: 'Supply',
      warehouse: 'Warehouse',
      planning: 'Planning',
      personnel: 'Personnel',
      assets: 'Masterdata',
      plantation: 'Plantation',
      demand: 'Demand',
    },
    operations: {
      progress: 'Work Progress',
      batches: 'Batches',
      workStationSessions: 'Work Station Sessions',
    },
    sales: {
      salesOrders: 'Sales & Projects',
    },
    planning: {
      productionLines: 'Production Areas',
      productionRequests: 'Workload',
      rampUps: 'Scenario Tool',
      workStations: 'Daily Planning',
      day: 'Day Overview',
    },
    warehouse: {
      stock: 'Stock',
      inbound: 'Receive Orders',
      outbound: 'Pick Orders',
      purchaseOrder: 'Purchase Orders',
      warehouseTransfer: 'Transfer Orders',
    },
    personnel: {
      users: 'Users',
      operators: 'Operators',
      leaveCalendars: 'Leave Calendars',
      leaveSlots: 'Leave Requests',
    },
    assets: {
      processes: 'Processes',
      articleTypes: 'Article Types',
      exactItems: 'Exact Articles',
      productionLines: 'Production Areas',
      capabilities: 'Skills',
      processPhases: 'Process Steps',
      dataSources: 'Data Sources',
      loadCarriers: 'Loading Carriers',
      processPhases: 'Process Phases',
      dataSources: 'Data Sources',
      globalValues: 'Settings',
      templates: 'Templates',
      globalFiles: 'Special Files',
      integrations: 'Integrations',
      projects: 'Projects',
      factories: 'Factories',
      syncrequest: 'Sync Requests'
    },
    plantation: {
      plantationLines: 'Plantation Areas',
      scripts: 'Scripts',
      units: 'Units'
    },
    account: {
      changelog: 'Changelog',
      clockInTime: 'Clocked in at {{clockInTime}}',
      notClockedIn: 'Not clocked in',
      clockIn: 'Clock In',
      clockOut: 'Clock Out',
      profile: 'Profile',
      logout: 'Logout',
      clockOutModal: {
        title: 'You are still clocked in.',
        message: 'Next to logging out, would you like to clock out as well?',
      },
    },
  },
  unit4requestoverview: {
    field: {
      id: {label: 'ID'},
      createdAt: {label: 'Created At'},
      message: {label: 'Message'}

    }
  },
  integrations: {
    field: {
      syncRequest: {label: 'Sync requests'},
      isOpen: {label: 'Show open tasks only'},
      message: {value: 'Increase stock for item with product code {{productID}} with quantity: {{quantity}} '}
    },
    overview: {
      empty: 'There are no sync requests matching your filters'
    }
  },
  profile: {
    title: 'Profile',
    changePassword: {
      label: 'Change Password',
      passwordOld: 'Current Password',
      passwordNew: 'New Password',
    },
  },
  unit: {
    overview: {
      title: 'Units'
    },
    field: {
      name: {
        label: 'Name'
      }
    }
  },
  user: {
    overview: {
      title: 'Users',
      empty: 'No users found.',
      addButton: 'User',
      convertToOperator: 'Convert to operator',
    },
    create: {
      title: 'Add {{fullName}}',
    },
    edit: {
      title: 'Edit {{fullName}}',
      saveBeforeLeaveSlot: 'You must first save this user before you can add leave.',
      tab: {
        info: 'Info',
        availability: 'Availability',
      },
    },
    field: {
      leaveBalances: { label: 'Leave Overview' },
      avatar: { label: 'Avatar' },
      fullName: { label: 'Name', unknown: 'User' },
      firstName: { label: 'First name' },
      lastName: { label: 'Last name' },
      email: { label: 'E-mail' },
      password: { label: 'Password' },
      dateJoined: { label: 'Added' },
      manager: { label: 'Manager' },
      language: { label: 'User language' },
      badgeId: { label: 'Badge ID' },
      groups: {
        label: 'Groups',
        value: {
          superuser: 'Super user',
          admin: 'Admin',
          manager: 'Manager',
        },
        empty: 'No groups joined',
      },
      availability: { label: 'Availability' },
      workSchedules: { label: 'Schedules' },
      leaveCalendars: { label: 'Leave Calendars' },
      extraLeaveBalances: {
        label: 'Remaining balance',
        empty: 'No remaining balance added',
      },
      isOperator: { label: 'Is Operator' },
      editOpenProductionOrder: {
        label: 'Can Edit Open Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
      releaseProductionOrder: {
        label: 'Can Release Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
      editReleasedProductionOrder: {
        label: 'Can Edit Released Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
    },
    login: {
      title: 'Log in',
      reloginMessage: 'You are logged out. Please login again.',
      forgotPasswordLink: 'Forgot password?',
      errors: {
        invalidCredentials: 'Email or password incorrect',
        unknown: 'Unknown error, status code: {{status}}',
      },
      loginButton: 'Log in',
      backButton: 'Go back',
      workStationStartButton: 'Start Work Station',
      operatorStartButton: ' Log in as Operator',
      or: 'Or',
    },
    passwordForgot: {
      title: 'Forgot password',
      requestButton: 'Send Email',
      requestedEmailText: 'An email has been send to the correct address.',
    },
    resetPassword: {
      title: 'Password reset',
      resetButton: 'Reset',
      saveSuccess: 'Password reset successfull.',
    },
    activate: {
      title: 'Activate account',
      intro: 'Welcome to Tracy! You just need to set a password to activate your account.',
      activateButton: 'Activate account',
      saveSuccess: 'Your account is activated.',
    },
    changePassword: {
      title: 'Change password',
      field: {
        passwordOld: {
          label: 'Current password',
        },
        passwordNew: {
          label: 'New password',
        },
      },
      saveButton: 'Save',
    },
    account: {
      title: 'Profile',
      logoutButton: 'Log out',
      info: {
        title: 'Info',
      },
      password: {
        title: 'Change password',
      },
      logout: {
        title: 'Log out',
        confirm: 'Are you sure you want to log out?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Articles',
      empty: 'No articles found.',
      viewLink: '(bekijken)',
      actions: {
        label: 'Change pairing ({{count}})',
        action: {
          linkArticleTypes: 'Link ({{count}})',
          linkArticleTypes_plural: 'Link article types ({{count}})',
          unlinkArticleTypes: 'Unlink article types ({{count}})',
          unlinkArticleTypes_plural: 'Unlink article types ({{count}})',
        },
      },
    },
    edit: {
      title: 'Edit Article',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      classification: { label: 'Group' },
      isMakeItem: { label: 'Make' },
      isBuyItem: { label: 'Buy' },
      name: { label: 'Name' },
      articleType: { label: 'Article Type' },
      componentType: { label: 'Component Type' },
    },
  },
  operator: {
    home: {
      welcome: 'Welcome, {{firstName}}!',
      clockInTime: 'You clocked in at {{clockInTime}}.',
      notClockedIn: 'You are currently not clocked in.',
      availability: 'Availability',
      scheduled: {
        notScheduled: 'You are not scheduled on any work stations today.',
        title: 'Today you are scheduled on the following work stations:',
        times: '{{startAt}} - {{endAt}}',
      },
    },
    availability: {
      title: 'Availability',
    },
    logoutModal: {
      title: 'Logout',
      content: 'Are you sure you want to log out?',
      logoutButton: 'Logout',
    },
    start: {
      title: 'Log in as Operator',
      startButton: 'Log in',
    },
    overview: {
      title: 'Operators',
      empty: 'No operators found',
      addButton: 'Operator',
    },
    create: {
      title: 'Add {{name}}',
    },
    edit: {
      title: 'Edit {{name}}',
      saveBeforeLeaveSlot: 'You must first save this operator before you can add leave.',
      tab: {
        info: 'Info',
        capabilities: 'Skills',
        availability: 'Availability',
      },
      workScheduleDescription: `Schedule from {{startDate}}`,
    },
    field: {
      leaveBalances: { label: 'Leave Overview' },
      avatar: { label: 'Avatar' },
      fullName: { label: 'Name', unknown: 'Operator' },
      firstName: { label: 'First name' },
      lastName: { label: 'Last name' },
      operatorCapabilities: {
        label: 'Skills',
        empty: 'No skills added',
        none: 'No skills configured in the system.',
      },
      createdAt: { label: 'Added' },
      badgeId: { label: 'Badge ID' },
      workSchedules: {
        label: 'Schedules',
        empty: 'No schedules added',
      },
      availability: { label: 'Availability' },
      leaveCalendars: { label: 'Leave Calendars' },
      manager: { label: 'Manager' },
      hasPendingLeave: { label: 'Pending Leave Requests' },
      extraLeaveBalances: {
        label: 'Remaining balance',
        empty: 'No remaining balance added',
      },
      deleted: { label: 'Deleted' },
    },
  },
  articleTypeWarehouse: {
    overview: {
      empty: 'No stock found',
    },
    field: {
      stock: { label: 'Stock', },
    },
    articleType: {
      id: 'ID',
      code: 'Code',
      classification: 'Group',
      name: 'Name',
    },
    warehouse: {
      name: 'Warehouse',
    },
    storageLocations: {
      field: {
        lines: { label: 'Storagelocations' },
        code: { label: 'Code' },
        default: { label: 'Default' },
        name: { label: 'Name' },
        stock: { label: 'Stock' },
      },
      noStorageLocation: 'no storage location specified',
    },
  },
  salesOrder: {
    basicInfo: 'Details',
    overview: {
      title: 'Sales Orders',
      empty: 'No sales orders found.',
      responsibilityModal: {
        title: {
          edit: 'Edit Responsibility',
          add: 'Add Responsibility',
        },
      },
      allocated: {
        label: 'Allocated',
        value: 'Allocated: {{count}}/{{total}}',
      },
      ready: {
        label: 'Allocated Order(s)',
        value: 'Allocated Order(s): {{count}}/{{total}}',
      },
      inStock: {
        label: 'In Stock',
        value: 'In Stock: {{count}}/{{total}}',
      },
      planned: {
        label: 'Planned',
        value: 'Planned: {{count}}/{{total}}',
      },
      picked: {
        label: 'Picked',
        value: 'Picked: {{count}}/{{total}}',
      },
      shipped: {
        label: 'Shipped',
        value: 'Shipped: {{count}}/{{total}}',
      },
      delivered: {
        label: 'Delivered',
        value: 'Delivered: {{status}}',
      },
      invoiced: {
        label: 'Invoiced',
        value: 'Invoiced: {{status}}',
      },
      exactReturned: {
        label: 'Returned',
        value: 'Returned: {{count}}/{{total}}',
      },
      showMore: 'Show more ({{count}} line)',
      showMore_plural: 'Show more ({{count}} lines)',
      showLess: 'Show less',
      quantities: {
        projected: '# projected',
        planned: '# planned',
        warehouse: '# warehouse',
        required: '# required',
        allocated: '# allocated',
        expected: '# expected',
      },
    },
    edit: {
      title: 'Edit Sales Order',
      allocateButton: 'Allocate',
      stage: {
        progress: { label: 'Progress' },
        general: { label: 'General' },
        customer: { label: 'Customer' },
        dates: { label: 'Dates' },
        salesOrderLines: { label: 'Sales Order Lines' },
        pickOrders: { label: 'Pick Orders' },
        deliveryAddress: { label: 'Delivery Address' },
        invoiceAddress: { label: 'Invoice Address' },
        remarks: { label: 'Internal Notes' },
        responsibilities: { label: 'Responsibilities' },
      },
      allocateModal: {
        title: 'Allocate {{articleType}} ({{allocated}}/{{required}})',
        allocate: 'Allocate',
        source: {
          quantity: 'Quantity',
          unallocated: 'Available',
          allocated: 'Already Allocated',
          date: 'Due Date',
          quantityStatus: {
            label: 'Status',
            value: {
              open: 'Open',
              in_progress: 'In Progress',
              done: 'Done',
            },
          },
          hasAvailable: { label: 'Available' },
          hasAllocated: { label: 'Allocated' },
        },
      },
    },
    confirmDelete: 'Are you sure you want to delete this sales order?',
    field: {
      id: { label: 'ID' },
      erpId: { label: 'ERP ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Partial',
          complete: 'Completed',
          canceled: 'Canceled',
        },
      },
      information: { label: 'Information' },
      customer: { label: 'Customer' },
      reference: { label: 'Reference' },
      orderNumberErp: { label: 'Number' },
      orderDate: { label: 'Order date' },
      deliveryDate: { label: 'Delivery date' },
      deliveryAddress: { label: 'Delivery address' },
      remarks: { label: 'Remarks' },
      description: { label: 'Description' },
      outShipments: {
        label: 'Pick Orders',
        empty: 'No pick orders added yet.',
        value: 'Pick Order {{id}}',
      },
      lines: { label: 'Sales Order Lines' },
      quantity: { label: 'Quantity' },
      exactSalesOrder: { label: 'Sales Order' },
      warehouse: { label: 'Warehouse' },
      allocationStatus: {
        label: 'Status',
        value: {
          unallocated: 'Unallocated (completely or partially)',
          not_ready: 'All allocated but not ready',
          not_shipped: 'All ready but not shipped',
          all_shipped: 'All Shipped',
        },
      },
      stockStatus: {
        label: 'Stock Status',
        value: {
          no_stock: 'No stock present',
          partial: 'Partial stock present',
          complete: 'Complete stock present',
        },
      },
      priority: {
        label: 'Priority',
        value: {
          high: '1 - High',
          medium: '2 - Medium',
          low: '3 - Low',
        },
      },
      responsibilities: { empty: 'No responsibilities added yet.' },
      shippingMethod: { label: 'Shipping Method' },
    },
    filters: {
      todo: 'Todo',
      inProgress: 'In Progress',
      complete: 'Complete',
      overdueReceipts: 'Overdue (without work orders)',
      overdueRequests: 'Overdue (with work orders)',
      receiptsThisWeek: 'Planned this week',
      futureReceipts: 'Planned future',
      deleted: 'Deleted',
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Add',
    consumedLine: 'No more available quantity for the selected sales order line(s).',
    field: {
      id: { label: 'ID' },
      project: { label: 'Project' },
      productionDueDate: { label: 'Order end date' },
      deliveryDate: { label: 'Delivery date' },
      quantity: { label: 'Quantity' },
      _toReceive: { label: 'Te receive' },
      unitPrice: { label: 'Price per piece' },
      netPrice: { label: 'Net Price' },
      salesOrder: { label: 'Sales Order' },
      code: { label: 'Article Code' },
      erpLines: { label: 'ERP' },
      decimalQuantity: { label: 'Actual ERP Quantity'},
      description: { label: 'Description' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Number' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Partial',
          30: 'Completed',
          40: 'Canceled',
        },
      },
    },
  },
  exactSalesOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Number' },
    },
  },
  purchaseOrder: {
    overview: {
      empty: 'No Purchase Orders found.',
    },
    confirmDelete: 'Are you sure you want to delete this purchase order?',
    filters: {
      status: 'Status',
      requestStatus: 'Work Order Status',
      ordersDueAndExpected: 'Orders due & expected',
      number: 'Number',
      todo: 'Todo',
      inProgress: 'In Progress',
      complete: 'Complete',
      overdueReceipts: 'Overdue (without work orders)',
      overdueRequests: 'Overdue (with work orders)',
      receiptsThisWeek: 'Planned this week',
      futureReceipts: 'Planned future',
      deleted: 'Deleted',
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Partial',
          complete: 'Complete',
          canceled: 'Canceled',
        },
      },
      supplier: { label: 'Supplier' },
      quantity: { label: 'Quantity' },
      reference: { label: 'Reference' },
      orderNumberErp: { label: 'Number' },
      orderDate: { label: 'Order date' },
      receiptDate: { label: 'Receipt date', error: 'Receipt date cannot be empty.' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Licence plate' },
      lines: { label: 'Purchase Order Lines' },
      inShipments: {
        label: 'Receipt Orders',
        empty: 'No receipt orders added yet.',
        value: 'Receipt Order {{id}}',
      },
      exactPurchaseOrder: { label: 'Purchase Order' },
      warehouse: { label: 'Warehouse' },
      linkedSalesOrder: { label: 'Linked Sales Order ERP' },
      shippingMethod: { label: 'Shipping Method' },
    },
  },
  purchaseOrderLine: {
    noBatchTypeBuy: 'No batch type `buy` defined for this article',
    consumedLine: 'No more available quantity for the selected purchase order line(s).',
    addToInShipmentButton: 'Add',
    field: {
      id: { label: 'ID' },
      project: { label: 'Project' },
      productionRequest: { label: 'Work Order' },
      receiptDate: { label: 'Receipt date' },
      quantity: { label: 'Quantity' },
      code: { label: 'Article Code' },
      _toReceive: { label: 'To receive' },
      unitPrice: { label: 'Unit price' },
      netPrice: { label: 'Net price' },
      purchaseOrder: { label: 'Purchase Order' },
      purchaseUnit: { label: 'Ordering Unit' },
      erpLines: { label: 'ERP' },
      decimalQuantity: { label: 'Actual ERP Quantity'},
      description: { label: 'Description' },
    },
  },
  inShipment: {
    disabledDelete: 'Cannot delete Receipt Order because there are related Work Orders',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Receipt date' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'Licence plate' },
      shipperName: { label: 'Driver' },
    },
    edit: {
      title: 'Edit Receipt Order',
    },
    missingReceiveProcessCreate: 'Create missing Receive Order process',
  },
  inShipmentLine: {
    disabledDelete: 'Cannot delete line because Work Order is in progress or completed',
    field: {
      id: { label: 'ID' },
      autoPerformedProductionRequest: { info: 'This line was added automatically because more articles were received than specified in the Purchase Order.' },
      inShipment: { label: 'Receipt ID' },
    },
    create: {
      title: 'Create Receipt Order Line',
    },
    edit: {
      title: 'Edit Receipt Order Line',
    },
    missingBuyProcessWarning: 'No Inbound process found for this article type.',
    missingBuyProcessCreate: 'Create missing Inbound process',
  },
  outShipment: {
    disabledDelete: 'Cannot delete Pick Order because there are related Work Orders',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Pickup date' },
      shipperName: { label: 'Driver' },
      shipperLicensePlate: { label: 'Licence plate' },
      freightType: { label: 'Cargo type' },
      freightCompany: { label: 'Transport company' },
      freightInfo: { label: 'Cargo info' },
      outShipmentTemplate: { label: 'Packing Slip Template' },
    },
    missingPickProcessCreate: 'Create missing Pick Order process',
  },
  outShipmentLine: {
    disabledDelete: 'Cannot delete line because Work Order is in progress or completed',
    field: {
      id: { label: 'ID' },
      outShipment: { label: 'Pick ID' },
    },
    create: {
      title: 'Create Pick Order',
    },
    edit: {
      title: 'Edit Pick Order',
    },
    missingSellProcessWarning: 'No Outbound process found for this article type.',
    missingSellProcessCreate: 'Create missing Outbound process',
  },
  capability: {
    overview: {
      title: 'Skills',
      empty: 'No skills found.',
      addButton: 'Skill',
    },
    create: {
      title: 'Add Skill',
    },
    edit: {
      title: 'Edit Skill',
    },
    field: {
      name: { label: 'Name' },
      operatorCapabilities: {
        label: 'Operators',
        none: 'No operators configured',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Process steps',
      empty: 'No process steps found',
      addButton: 'Process step',
    },
    create: {
      title: 'Proces Fase Aanmaken',
    },
    edit: {
      title: 'Proces Fase Aanpassen',
    },
    field: {
      icon: { label: 'Icoon' },
      name: { label: 'Naam' },
    },
  },
  batchType: {
    overview: {
      title: 'Processes',
      empty: 'No processes found.',
    },
    create: {
      title: 'Add Process',
    },
    onTheFly: {
      title: 'On the Fly Settings',
      description: "With 'On-the-Fly' TRACY can automatically create a new batch when a serial number is scanned which isn't known yet. The entered serial number has to match with the serial format.",
    },
    edit: {
      title: 'Edit Process',
      bulkTemplateButton: 'Bulk Add Template',
      bulkTemplateModal: {
        title: 'Bulk Add Template',
        selectButton: 'Add to {{count}} Article Type',
        selectButton_plural: 'Add to {{count}} Article Types',
        selectAllButton: 'Add to all {{count}} Article Types',
      },
      bulkTemplateProgressModal: {
        title: 'Bulk Add Progress',
        progress: '{{count}} of {{total}} added.',
        timeRemaining: '{{time}} remaining.',
      },
      finalizeProgressModal: {
        title: 'Finalizing process template progress',
      },
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      quantity: { label: 'Batchsize' },
      name: { label: 'Batchsize' },
      articleType: { label: 'Article Type' },
      onTheFlySerialNumberFormat:{label: 'On the Fly Serial Number Format'},
      type: {
        label: 'Type',
        value: {
          make: 'Make',
          buy: 'Inbound',
          sell: 'Outbound',
          transfer_line: 'Transfer line',
          component: 'Component',
          subassembly: 'Subassembly',
          stock_count: 'Stock Count',
          stock_count_template: 'Stock Count Template',
          transfer: 'Transfer',
          on_the_fly: 'On the Fly',
          pick_order: 'Pick Order',
          receive_order: 'Receive Order',
          make_template: 'Make Template',
          buy_template: 'Inbound Template',
          sell_template: 'Outbound Template',
          transfer_line_template: 'Transfer line template',
        },
      },
      serialNumberFormat: { label: 'Serial Format' },
      default: { label: 'Default' },
      source: { label: 'Template' },
      targets: {
        label: 'Article Types',
        value: 'Used in {{count}} article type.',
        value_plural: 'Used in {{count}} article types.',
      },
      onTheFlyType: {
        label: 'On the Fly Batch Size',
        info: 'This setting determines which size the batches will be when they are created on the fly.',
        value: {
          none: 'None',
          fixed: 'Fixed',
          flexible: 'Flexible',
        },
        tooltip: {
          none: 'If you select this option we will not actually look at the quantity of the created batch, it can be used infinitly many times.',
          fixed: 'If you select this option you will have to enter a fixed size for the created batches.',
          flexible: 'If you select this option the operator will be prompted to enter the size of the batch when a new batch has to be created.',
        },
      },
      onTheFlySource: {
        label: 'On the Fly Source',
        info: 'This setting determines where the batch that will be created on the fly comes from.',
        value: {
          new: 'New',
          existing: 'Existing',
        },
        tooltip: {
          new: 'A new batch will be created.',
          existing: 'The system will prompt to scan an existing batch that the new batch will be taken from as a subbatch.',
        },
      },
    },
  },
  articleType: {
    overview: {
      title: 'Article Types',
      empty: 'No article types found.',
      addButton: 'Article Type',
      viewBOMButton: 'View BOM',
      copyBatchTypeButton: 'Copy Batch Types',
    },
    printShelfLabelButton: 'Stock location label',
    selectModal: {
      title: 'Choose article type',
    },
    create: {
      title: 'Add article type',
    },
    edit: {
      baseFields: {
        label: 'Basic fields',
        info: 'These fields do not belong to a process type, but apply to the entire article.',
      },
      title: 'Edit Article',
      version: 'Version {{version}}',
      draft: 'Concept',
      finalizeButton: 'Finalise',
      nextVersionButton: 'Next version',
      noStepSelected: 'No step selected',
      workStationsModal: {
        title: 'Select Work Stations',
      },
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied succesfully',
        pasted: 'Pasted succesfully',
        pasteError: 'Nothing to paste',
      },
      bomFieldsModal: {
        title: 'BOM Fields',
        content: 'This process contains form fields of type \'BOM\'. Switching to another batch type than \'make\' will cause these fields to be deleted.',
      },
      endWithVariableQuantity: 'The process cannot end with a variable quantity. Add a quantity form field to define the actual batch size.',
      splitWithVariableQuantity: 'A batch step cannot be used on a variable quantity.',
      quantityFieldWithoutVariableQuantity: 'A quantity field cannot be used without a variable quantity.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Name' },
      line: { label: 'Production Area' },
      versions: { label: 'Versions' },
      batchTypes: { label: 'Processes' },
      articleType: { label: 'Article Type' },
      componentType: { label: 'Component type' },
      storageLocations: { short: 'Storage Location(s)', long: 'Storage Location(s) - Quantity' },
      classification: { label: 'Group' },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
      isMakeOrBuy: { label: 'Make/Buy' },
      isMake: { label: 'Make' },
      isBuy: { label: 'Buy' },
      availableBatches: {
        label: 'Available Batch(es)',
        value: '{{count}} available batch',
        value_plural: '{{count}} available batches',
        noBatch: 'No available batch',
      },
      isSerial: { label: 'Serial' },
      totalStock: { label: '# available' },
      storageLocationStock: { label: 'Storage location Stock' },
      deleted: { label: 'Deleted' },
      endDateBeforeCurrDate: {label: 'Show end-date before today'},
      trackBatchUsage: { label: 'Track batch usage'},
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Article Type' },
      productionLineVersion: { label: 'Production Area Version' },
    },
  },
  plantationLine: {
    overview: {
      title: 'Plantation Areas',
      empty: 'No plantation area found.',
      addButton: 'Plantation Area',
      details: 'Details',
      filters: 'Filters',
      rack: { label: 'Rack', value: '{{rack}}' },
      location: { label: 'Position', value: 'R{{rack}}L{{layer}}P{{position}}' },
      container: { label: 'Container' },
      time: {
        start: 'Start',
        end: 'End',
      },
      scripts: {
        label: 'Values',
        setpoint: {
          name: 'Name',
          value: 'Value',
        },
      },
    },
    create: {
      title: 'Add Plantation Area',
    },
    edit: {
      title: 'Edit Plantation Area',
    },
  },
  productionLine: {
    overview: {
      title: 'Production Areas',
      empty: 'No production area found.',
      addButton: 'Production Area',
    },
    planning: {
      title: 'Production Areas',
      empty: 'No production area found.',
      productionLine: 'Production Area',
      week: 'Week',
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      parts: {
        night: 'Night (00:00 - 06:00)',
        morning: 'Morning (06:00 - 12:00)',
        afternoon: 'Afternoon (12:00 - 18:00)',
        evening: 'Evening (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Add Production Area',
    },
    edit: {
      baseFields: {
        label: 'Global settings',
        info: 'These fields do not belong to a version but are applicable to every version.',
      },
      title: 'Edit Production Area',
      version: 'Version {{version}}',
      draft: 'Concept',
      finalizeButton: 'Finalise',
      nextVersionButton: 'Next version',
      tabs: {
        map: 'Map',
        codes: 'Codes',
        availability: 'Availability',
      },
    },
    selectModal: {
      title: 'Select a production area',
    },
    codesModal: {
      title: 'Codes overview',
      noPrintersAvailable: 'No printers available.',
    },
    field: {
      name: { label: 'Name' },
      fullName: { label: 'Name' },
      versions: { label: 'Versions' },
      timezone: { label: 'Timezone' },
      color: {
        label: 'Colour',
        value: {
          red: 'Red',
          orange: 'Orange',
          yellow: 'Yellow',
          olive: 'Olive green',
          green: 'Green',
          teal: 'Turquoise',
          blue: 'Blue',
          violet: 'Violet',
          purple: 'Purple',
          pink: 'Pink',
          brown: 'Brown',
          grey: 'Gray',
          black: 'Black',
        },
      },
      workSchedules: {
        label: 'Schedules',
        errors: {
          missing: 'Production line must have a valid schedule.',
        }
      },
      leaveCalendars: { label: 'Leave calendars' },
      factory: { label: 'Factory' },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Version' },
      draft: { label: 'Concept' },
      floorPlan: { label: 'Layout' },
      workStations: {
        label: 'Work Stations',
        addButton: 'Work Station',
        error: {
          missing: 'At least one work station is required.',
        }
      },
      productionLine: { label: 'Production Area' },
    },
  },
  workSchedule: {
    field: {
      employer: { label: 'Employer', placeholder: 'Start typing to search or add employers' },
      startDate: { label: 'Start Date' },
      workSlots: {
        label: 'Work Times',
        empty: 'No work times have been added yet',
      },
      allowedLegalLeaveMinutes: { label: 'Legal Vacation Minutes' },
      allowedExtraLeaveMinutes: { label: 'Extra Vacation Minutes' },
      allowedRwhMinutes: { label: 'RWH-days' },
    },
  },
  workStation: {
    field: {
      name: { label: 'Name' },
      code: { label: 'Code' },
      dataSources: { label: 'Data source' },
      defaultFte: { label: 'Standard staff' },
      singleUser: {
        label: 'Login behaviour',
        value: {
          true: 'Single Login',
          false: 'Multi Login',
        },
        tooltip: {
          true:
            "When set as 'Single Login' The operator is asked to scan the badge once. By default, after 4h the station will auto log off. This time can be changed by changing the variable single_user_work_station_timeout_minutes in the general settings.",
          false: "When set as 'Multi Login' The operator is asked to scan the badge every step.",
        },
      },
      productionStrategy: {
        label: 'Production strategy',
        value: {
          together: 'Together',
          seperate_spread: 'Separate spread',
          seperate_planned: 'Separate planned',
        },
        tooltip: {
          together: "When set as 'samen' The stations will act as 1 station",
          seperate_spread: "When set as 'gescheiden (verdeeld)' The stations will work separately on the same orders.",
          seperate_planned: "When set as 'gescheiden (gepland)' The stations will work on different orders as planned.",
        },
      },
      maintenanceStrategy: {
        label: 'Maintenance strategy',
        value: {
          together: 'Samen',
          seperate: 'Gescheiden',
        },
        tooltip: {
          together: "When set as 'samen' Maintenance will be performed for all locations at a time.",
          seperate: "When set as 'gescheiden' Maintenance will be planned and performed for each location separately.",
        },
      },
      steps: { label: 'Steps' },
      automationDate: { label: 'Automation Date' },
      work: { label: 'Work' },
      workAssigned: { label: 'Assigned' },
      workUnassigned: { label: 'Unassigned' },
      productionRequests: { label: 'Work Orders' },
    },
    start: {
      title: 'Login Work Station',
      startButton: 'Start',
    },
    production: {
      title: 'Production',
      empty: 'No orders could be found.',
      stopButton: 'Stop',
      selectButton: 'Perform',
      version: 'Version {{version}}',
      operatorModal: {
        title: 'Scan Badge ID',
        confirmButton: 'Confirm',
        error: {
          noResults: 'Badge-ID not found.',
          emptyField: 'Enter the Badge-ID of the operator.',
          multipleResults: 'Multiple operators found with same ID.',
          unexpected: 'A unexpected error occured.',
          missingCapabilities: 'Incorrect skills: {{capabilities}}.',
          missingCapabilities_plural: 'The found operator does not have the correct skills: {{capabilities}}.',
        },
      },
      batchModal: {
        title: 'Scan Product',
        confirmButton: 'Confirm',
        error: {
          notFound: 'No product found with this serial number',
        },
      },
      performModal: {
        generalErrors: 'Some errors occured',
        title: 'Perform step',
        confirmButton: 'Confirm',
        formStep: {
          label: 'Serialnumber',
          subject: {
            article_type: 'Article',
          },
        },
        printStep: {
          selectPrinter: 'Select Printer',
          noPrintersAvailable: 'No Printers Available',
          printButton: 'Print',
          printedSuccesfully: 'Printed succesfully',
        },
        splitStep: {
          printButton: 'Print',
          printAllButton: 'Print All ({{count}})',
          reprintButton: 'Reprint',
          reprintButton_plural: 'Reprint ({{count}})',
          bulkEntrySerialNumbersDetail: 'You can scan multiple serial numbers at once.',
          wrongFormat: 'The serial number {{serialNumber}} does not match the predetermined format',
          expectedQuantity: 'You already scanned the expected number of serial numbers.',
          integrationNotActive: 'Exact integration is not activated or does not exist.',
          alreadySyncedToExact: 'The serial number {{serialNumber}} has already been scanned.',
        },
        quantityType: {
          quantity: 'Quantity',
          weight: 'Weight',
          error: {
            tooMuch: 'The quantity provided is higher than the quantity left for this order.',
          }
        },
        scan: {
          serialNumber: 'Serial Number',
          quantity: 'Quantity',
          error: {
            notFound: 'No batch found with the provided serial number.',
            wrongArticleType: 'The batch found with the provided serial number does not match the expected article type.',
            wrongBatchType: 'The batch found with the provided serial number is not of a batch type that can be scanned.',
            fullyUsed: 'The batch found with the provided serial number does not have any stock remaining.',
          },
        },
      },
      quantityModal: {
        title: 'Enter Batch Quantity',
        quantity: 'Quantity',
        confirmButton: 'Confirm',
      },
      infoModal: {
        title: 'Info',
      },
      fastForwardButton: 'Fast Forward',
      fastForwardModal: {
        title: 'Fast Forward',
        days: 'Days',
        confirmButton: 'Confirm',
      },
      operator: {
        expiresAt: 'Ends at{{at}}',
      },
      onTheFlyQuantityModal: {
        title: 'On the Fly Quantity',
        description: 'The scanned batch does not exist yet in the system. In order to create it on the fly the system needs to know its quantity.',
        placeholder: 'Quantity',
      },
      onTheFlySourceModal: {
        title: 'On the Fly Source',
        description: 'The scanned batch does not exist yet in the system. In order to create it on the fly the system needs to know the source batch it was taken from.',
        placeholder: 'Serial Number',
        error: {
          notFound: 'No batch found with the provided serial number.',
          wrongArticleType: 'The batch found with the provided serial number does not match the expected article type.',
          notEnough: 'The batch found with the provided serial number does not have enough stock remaining.',
        },
      },
    },
    editModal: {
      editTitle: 'Configure Work Station',
      viewTitle: 'Work Station Configuration',
    },
    stopModal: {
      title: 'End Work Station',
      content: 'Are you sure you want to end this Work Station?',
      stopButton: 'Stop',
    },
    planning: {
      title: 'Daily Planning',
      empty: 'Nothing to plan for this day.',
      assignmentsModal: {
        title: 'Daily Planning: {{name}}',
      },
      assignmentTimeModal: {
        title: 'Change Times',
        overlap: {
          label: 'These times overlap with some already assigned slots for this operator.',
          assignment: '{{workStation}} from {{start}} until {{end}}.',
        },
      },
      day: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        other: 'Other',
      },
      stats: {
        work: 'Hours of work',
        workAssigned: 'Assigned hours',
        workUnassigned: 'Unassigned hours',
        month: {
          january: 'JANUARY',
          february: 'FEBRUARY',
          march: 'MARCH',
          april: 'APRIL',
          may: 'MAY',
          june: 'JUNE',
          july: 'JULY',
          august: 'AUGUST',
          september: 'SEPTEMBER',
          october: 'OCTOBER',
          november: 'NOVEMBER',
          december: 'DECEMBER',
        },
      },
    },
    day: {
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
  },
  productionRequest: {
    registerExistingButton: 'Stock Count',
    overview: {
      title: 'Workload',
      empty: 'No work orders could be found',
      addButton: 'Production Order',
      productionRequestID: 'Work Order ID',
      inShipmentId: 'Receipt Order ID',
      outShipmentId: 'Pick Order ID',
      exactSalesOrder: 'Open Sales Order {{order}} in Exact',
      exactPurchaseOrder: 'Open Purchase Order {{order}} in Exact',
      exactShopOrder: 'Open Production Order {{order}} in Exact',
      purchaseOrderNumber: 'Purchase Order ID',
      stockCountNumber: 'Stock Count ID',
      salesOrderNumber: 'Sales Order ID',
      linkedSalesOrder: 'Linked Sales Order ERP',
      warehouseTransferID: 'Warehouse Transfer ID',
      warehouseTransferLineID: 'Warehouse Transfer Line ID',
      productionOrderNumber: 'Production Order ID',
      orders: 'Orders',
      done: '{{count}} product done',
      done_plural: '{{count}} products done',
      progress: '{{percentage}} of work done',
      extra: '+ {{count}} extra',
      extra_plural: '+ {{count}} extra',
      diff: {
        planned: 'Planned',
        actual: 'Actual',
        advance: 'Advance',
        delay: 'Delay',
      },
      information: 'Information',
      order: 'Order',
      processSteps: 'Process steps',
    },
    edit: {
      title: 'Edit Work Order',
      bom: 'BOM',
      storageLocations: 'STORAGE LOCATIONS',
      releaseButton: 'Release',
      stage: {
        articleType: {
          label: 'Article Type Info',
          bom: {
            notEnoughStock: "Not enough stock available to produce this quantity",
          },
          showBomBtn: "Show BOM",
        },
        warehouse: {
          label: 'Warehouse Info',
        },
        planning: {
          label: 'Planning',
          planned: 'Planned',
          actual: 'Actual',
          duration: 'Duration',
          start: 'Start',
          end: 'End',
        },
        bom: {
          label: 'BOM List',
          articleCode: 'Article Type Code',
          articleName: 'Article Type Name',
        },
        productionLines: {
          label: 'Production Areas',
        },
      },
    },
    field: {
      id: { label: 'Work Order ID' },
      articleType: { label: 'Article Type' },
      processVersion: { label: 'Version' },
      week: { label: 'Week' },
      quantity: { label: 'Quantity' },
      progress: { label: 'Progress' },
      startAt: { label: 'Start' },
      endAt: { label: 'End' },
      duration: { label: 'Duration' },
      project: { label: 'Project' },
      finished: { label: 'Finished' },
      productionOrder: { label: 'Production Order' },
    },
    filter: {
      itemGroup: 'Item Group',
      requestStatus: 'Work Order Status',
      inShipmentId: 'Receipt ID',
      outShipmentId: 'Pick ID',
      warehouseTransferID: 'Transfer ID',
      projectCode: 'Project Code',
      purchaseOrderNumberERP: 'Purchase Order ERP',
      salesOrderNumberERP: 'Sales Order ERP',
      shippingMethod: 'Shipping Method',
    },
    addModal: {
      title: 'Add Order',
      step: {
        pickWeek: 'Select week',
        pickArticleTypes: 'Select article type',
        setQuantities: 'Select quantity',
      },
      quantityWarning: 'Amending the order quanitity to 0 is equal to deleting the order.',
      noArticleTypes: 'No article types selected.',
      notFullyDeleted: 'The order could not be deleted because it is in progress.',
    },
    editModal: {
      title: 'Edit Order',
      title_plural: 'Edit Orders',
    },
  },
  myFilter: {
    custom: 'New',
    action: {
      unsetDefault: 'Use as standard',
      setDefault: 'Use as standard',
      setCurrent: 'Save current filters',
      delete: 'Delete',
    },
  },
  globalValue: {
    edit: {
      unsetButton: 'Unset',
      title: 'Edit {{key}}',
      operator_badge_print: {
        key: {
          operator_name: 'The operator\'s name.',
          badge_id: 'The operator\'s badge ID.',
        },
      },
      workstation_code_print: {
        key: {
          workstation_name: 'The work station\'s name.',
          workstation_code: 'The work station\'s code.',
        },
      },
      stock_location_print: {
        key: {
          article_type_code: 'The article type\'s code.',
          article_type_name: 'The article type\'s name.',
          warehouse_code: 'The warehouse\'s name.',
          warehouse_locations: 'The warehouse\'s locations.',
        },
      },
      loading_carrier_print: {
        key: {
          loading_carrier_serial: 'Serial number of the loading carrier',
          loading_carrier_name: 'Name of the loading carrier',
        },
      },
      login_url_print: {
        key: {
          slug: 'The slug of your tracy instance. ({{value}})',
          app_url: 'The base url of tracy. ({{value}})',
        },
      },
      progress_scope: {
        week: 'Week',
        month: 'Month',
        day: 'Day',
      },
    },
    overview: {
      title: 'Global settings',
      template: {
        empty: 'Template is empty',
        moreLines: '{{count}} more line hidden, hover to show.',
        moreLines_plural: '{{count}} more lines hidden, hover to show.',
      },
    },
    field: {
      key: {
        label: 'Setting name',
      },
      value: {
        label: 'Value',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  template: {
    field: {
      name: { label: 'Name' },
      file: { label: 'PDF' },
      type: {
        label: 'Type',
        value: {
          out_shipment: 'Packing Slip',
        },
      },
      isDefault: { label: 'Default' },
    },
    overview: {
      title: 'Templates',
      empty: 'No templates have been added',
      addButton: 'Template',
    },
    create: {
      title: 'Create Template',
    },
    edit: {
      title: 'Edit Template',
    },
    info: 'In a template you can add product variables by typing {{ and selecting the correct value.',
  },
  globalFile: {
    edit: {
      title: 'Edit special file',
    },
    overview: {
      title: 'Special files',
    },
    field: {
      key: {
        label: 'Name for file',
      },
      value: {
        label: 'File',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  tooltips: {
    masquerade: 'Masquerade as',
    edit: 'Edit',
    delete: 'Delete',
    restore: 'Restore',
    view: 'View',
    select: 'Select',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Add field',
    },
    field: {
      fields: {
        label: 'Fields',
        empty: 'The form is empty',
      },
    },
    // Form stuff
    cap: {
      remaining: '{{count}} remaining',
      tooMany: '{{count}} too many',
    },
    yes: 'Yes',
    no: 'No',
    either: 'Both',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'No icon selected',
    },
    applyButton: 'Apply',
    cancelButton: 'Cancel',
    deleteButton: 'Delete',
    restoreButton: 'Restore',
    backButton: 'Back',
    nextButton: 'Next',
    submitButton: 'Submit',
    saveButton: 'Save',
    downloadButton: 'Download',
    copyButton: 'Copy',
    addButton: 'Add',
    printButton: 'Print',
    bulkActionButton: 'Action ({{count}})',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Other',
      none: 'No file uploaded yet',
    },
    multiPick: {
      searchPlaceholder: 'Select…',
      selectedText: '$1 from $2 selected',
      noneSelectedText: 'None selected',
      selectAllButton: 'All',
      selectNoneButton: 'None',
    },
    notifications: {
      saveSuccess: 'Saved successfully',
      deleteSuccess: 'Deleted successfully',
      saveError: 'Error with saving ({{status}})',
      saveValError: 'Not all information is filled in correctly',
      saveAuthError: 'Invalid credentials',
      newAppVersion: 'New version available, click to reload page as soon as possible',
    },
    startDate: 'Start date',
    endDate: 'End date',
    deleteConfirmation: 'Are you sure you want to delete {{name}} ?',
    repeat: 'Repeat',
    greaterThan: 'Higher than',
    lowerThan: 'Lower than',
    greaterThanOrEqual: '>=',
    lowerThanOrEqual: '<=',
    duration: {
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
    },
  },
  crash: {
    heading: 'Application has crashed',
    subHeading1: 'We appologize, the application has crashed',
    subHeading2: 'Our team is notified.',
    reload: 'Reload page',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Select icon',
    filterPlaceholder: 'Search...',
    category: {
      favorites: 'Favorites',
      accessibility: 'Accessibility',
      arrows: 'Arrows',
      audioVideo: 'Audio/Video',
      business: 'Business',
      chess: 'Chess',
      code: 'Code',
      communication: 'Communication',
      computers: 'Computers',
      currency: 'Currency',
      dateTime: 'Date/Time',
      design: 'Design',
      editors: 'Editing',
      files: 'Files',
      genders: 'Genders',
      handsGestures: 'Hand signatures',
      health: 'Health',
      images: 'Pictures',
      interfaces: 'Interfaces',
      logistics: 'Logistics',
      maps: 'Cards',
      medical: 'Medical',
      objects: 'Objects',
      paymentsShopping: 'Shopping',
      shapes: 'Shapes',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'People',
      vehicles: 'Vehicles',
      writing: 'Writing',
    },
  },
  exact: {
    ok: {
      title: 'Exact connection successfull',
      body: 'Exact online is successfully connected.',
    },
    error: {
      title: 'Exact connection failed',
      body: {
        invalid_request: 'Sending request to exact failed.',
        invalid_client: 'Sending client to Exact failed.',
        invalid_grant: 'Sending grant to Exact failed.',
        invalid_scope: 'Sending cope to Exact failed. ',
        unauthorized_client: 'Sending onauthorised client to Exact.',
        unsupported_grant_type: 'Sending grant to Exact failed.',
      },
    },
  },
  unit4: {
    ok: {
      title: 'Unit 4 connection successfull',
      body: 'Unit 4 is successfully connected.',
    },
    error: {
      title: 'Unit 4 connection failed',
      body: {
        invalid_request: 'Sending request to exact failed.',
        invalid_client: 'Sending client to Exact failed.',
        invalid_grant: 'Sending grant to Exact failed.',
        invalid_scope: 'Sending cope to Exact failed. ',
        unauthorized_client: 'Sending onauthorised client to Unit 4.',
        unsupported_grant_type: 'Sending grant to Unit 4 failed.',
      },
    },
  },
  warehouse: {
    stock: 'Stock',
    inbound: 'Inbound',
    outbound: 'Outbound',
    field: {
      code: { label: 'Code' },
      name: { label: 'Name' },
    },
  },
  step: {
    edit: {
      step: 'Step {{index}}',
      start: 'Start',
      end: 'End',
      addSectionButton: 'Add component',
    },
    field: {
      newBatchSerialNumberFormat: { label: 'New Serial Number Format' },
      label: { label: 'Process Step Name' },
      type: {
        value: {
          print: 'Print Step',
          form: 'Form Step',
          split: 'Batch Step',
          multiplier: 'Multiplier',
          grow: 'Grow Step',
          subprocesses: 'Subprocesses',
        },
        valueStep: {
          print: 'Print Step',
          form: 'Form Step',
          split: 'Batch Step',
          multiplier: 'Multiplier',
          grow: 'Grow Step',
          subprocesses: 'Subprocesses',
        },
      },
      sections: {
        label: 'Description',
        empty: 'This description is empty.',
      },
      workStation: { label: 'Work Station' },
      workMinutes: { label: 'Estimated Process Time' },
      capabilities: { label: 'Skills required' },
      workTempoManual: {
        label: 'Work Tempo (Manual)',
        unit: 'in plants / hour',
      },
      workTempoAutomatic: {
        label: 'Work Tempo (Automatic)',
        unit: 'in plants / hour',
      },
      operatorSupervision: {
        label: 'Operator Supervision Required',
      },
    },
  },
  printStep: {
    field: {
      type: {
        label: 'Print Type',
        value: {
          label: 'Label',
          document: 'Document',
        },
      },
      copies: { label: 'Default Number of Copies' },
      labelPrinter: {
        label: 'Printer',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      labelTemplate: {
        label: 'Template',
        keys: {
          article_type_name: 'Article name.',
          article_type_code: 'Article code.',
          article_type_gross_weight: 'Article gross weight.',
          warehouse_name: 'Warehouse name.',
          warehouse_code: 'Warehouse code.',
          warehouse_locations: 'Warehouse locations.',
          serial_number: 'Product serialnumber.',
          batch_size: 'Batch size',
          date: 'Date.',
          time: 'Time.',
          shipment_lines: 'Shipment lines table.',
          operator_badge_id: 'Operator Badge ID',
          operator_name: 'Operator Name',
          order_id: 'Order ID',
          erp_order_id: 'ERP Order ID',
          customer_name: 'Order Customer Relation Name',
          supplier_name: 'Order Supplier Relation Name',
          reference: 'Order Reference',
          remarks: 'Order Remarks',
          delivery_address: 'Order Delivery Address',
          delivery_date: 'Order Delivery Date',
          receipt_date: 'Order Receipt Date',
          quantity: 'Order Quantity',
          sales_order_line_description: 'Sales Order Line Description',
          purchase_order_line_description: 'Purchase Order Line Description',
          amount_ex_vat: 'Amount Excluding VAT',
          shipping_method_description: 'Shipping Method Description',
        },
      },
      documentBackground: { label: 'Background' },
      documentOverlays: {
        label: 'Template',
        template: 'Template',
        addPage: 'Page',
      },
      defaultPrinter: { label: 'Default Printer' },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Number of times' },
    },
  },
  formStep: {
    field: {
      form: { label: 'Form' },
      icon: { label: 'Icon' },
      optional: { label: ' (Optional)' },
    },
    perform: {
      between: 'Between {{min}} and {{max}}',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Form' },
      icon: { label: 'Icoon' },
      newBatchQuantity: {
        label: 'New Batch Size',
        info: 'One container contains one batch with X amount of plants. X is in this case the batch size.',
      },
      newBatchVariableQuantity: {
        label: 'Variable',
        info: 'Quantity is an expected quantity, the real quantity can vary.',
      },
      newBatchVariableQuantityPredetermined: {
        label: 'Variable Predetermined',
        info: 'The variable quantity is predetermined, this enables the quantity to already affect the step itself for certain form fields and how much you need to scan.',
      },
      newBatchVariableUseOrderSize: {
        label: 'Use order size?',
        info: 'The expected quantity is variable, this enables the quantity field to have a default value of the order size.',
      },
      printer: { label: 'Printer' },
      template: { label: 'Template' },
      copies: { label: 'Default Number of Copies' },
      type: {
        label: 'Identification by',
        value: {
          scan: 'Loading Carrier',
          print: 'Print / Label',
          article: 'Article Identification',
          provided: 'Provided Serial Number',
        },
        tooltip: {
          scan: "At identification by 'Loading Carrier' the batch is paired with the scanned loading carrier.",
          print: "At identification by 'Print/Label' a label is printed according the set template. If you do not add form fields to this batch step, you can labels with the printall feature.",
          article:
            "At identification by 'Article Identifaction' an article type should be scanned that is used in the process of identification.",
          provided: "At identification by 'Provided Serial Number' the serial number by which the batch should be identified is provided by the operator. This still has to match the new serial number format defined above.",
        },
      },
      defaultPrinter: { label: 'Default Printer' },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Label' },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          choice: 'Choice',
          article_type: 'Article Type',
          measure: 'Measurement',
          format: 'Format',
          variable: 'Variable',
          image: 'Image',
          quantity: 'Quantity',
          bom: 'BOM',
          storage_location: 'Storage Location'
        },
      },
      target: { label: 'Details' },
      articleType: { label: 'Article Type' },
      slug: {
        label: 'Print Reference',
        info:
          'This value can be used in other print templates. Only characters A-Z, a-z, 0-9 en _ are allowed and the value cannot start with a number',
      },
      storageLocations: {label: 'Storage location(s) (Quantity)'}
    },
  },
  batch: {
    batchInfoModal: {
      trigger: 'Batch Info',
    },
    overview: {
      title: 'Products',
      empty: 'No products are found.',
    },
    field: {
      batchType: { label: 'Type' },
      productionRequest: { label: 'Work ID' },
      inShipment: { label: 'Receipt ID' },
      outShipment: { label: 'Pick ID' },
      warehouseTransfer: { label: 'Transfer ID' },
      purchaseOrderNumberERP: { label: 'Purchase Order ERP' },
      salesOrderNumberERP: { label: 'Sales Order ERP' },
      productionOrderNumberERP: { label: 'Production Order ERP' },
      orderNumberERP: { label: 'ERP Order' },
      batchUseds: {
        label: 'Used by',
        empty: 'This batch has not been used in any other batches.',
      },
      batchUsings: {
        label: 'Used',
        empty: 'This batch has not used any other batches.',
      },
      details: {
        label: 'Details',
        empty: 'This product has no details.',
      },
      performances: {
        label: 'Performances',
        empty: 'This product has no performances.',
        description: '{{step}} performed by {{operator}} @ {{createdAt}}',
        printedDocument: 'Printed document',
      },
      serialNumber: { label: 'Serial number' },
      finished: { label: 'Done' },
      scrapped: { label: 'Rejected' },
      loadCarrier: { label: 'Loading carrier' },
      quantityRemaining: { label: 'Quantity Remaining' },
      storageLocation: { label: 'Storage Location' },
    },
  },
  subassembly: {
    overview: {
      title: 'Sub products',
      empty: 'No sub products are found.',
    },
    field: {
      parentBatch: { label: 'Master product' },
      parentSubassembly: { label: 'Master sub product' },
      subassemblies: {
        label: 'Sub products',
        empty: 'This sub products has no other sub products.',
      },
      details: {
        label: 'Details',
        empty: 'This sub products has no details.',
      },
      performances: {
        label: 'Performances',
        empty: 'This sub product has no performances.',
        description: '{{step}} performed by {{operator}} @ {{createdAt}}',
      },
      serialNumber: { label: 'Serial number' },
      finished: { label: 'Done' },
    },
  },
  progress: {
    title: 'Progress overview',
    empty: 'There are no orders for this week.',
    processType: {
      label: 'Process Type',
      value: {
        make: 'Main Make',
        buy: 'Sub Inbound',
        sell: 'Sub Outbound',
        transfer_line: 'Sub Transfer line',
        stock_count: 'Main Stock Count',
        transfer: 'Main Transfer',
        pick_order: 'Main Pick Order',
        receive_order: 'Main Receive Order',
      },
    },
    productionLine: {
      stat: {
        todo: 'To do',
        inProgress: 'In progress',
        done: 'Done',
        operators: 'Operator',
        operators_plural: 'Operators',
      },
    },
    weekPicker: 'Week',
    monthPicker: 'Month',
    dayPicker: 'Day',
    processVersion: 'Version{{version}}',
    groupBy: {
      label: 'Group by',
      value: {
        workStation: 'Work Station',
        step: 'Step',
      },
    },
  },
  detail: {
    field: {
      value: { label: 'Value' },
      reason: { label: 'Reason' },
    },
  },
  targetInfoModal: {
    scrapped: 'Rejected: {{reason}}',
    variableQuantity: 'Variable Quantity',
    reprint: {
      title: 'Reprint',
      quantity: 'Number of Copies',
      printButton: 'Print',
    },
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Text',
        content: 'Content',
      },
      date: {
        label: 'Date',
        part: {
          label: 'Part',
          year: { label: 'Year' },
          month: {
            label: 'Month',
            text: 'Text',
            names: {
              january: {
                label: 'January',
                value: 'JAN',
              },
              february: {
                label: 'February',
                value: 'FEB',
              },
              march: {
                label: 'March',
                value: 'MAR',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'May',
                value: 'MAY',
              },
              june: {
                label: 'June',
                value: 'JUN',
              },
              july: {
                label: 'July',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'December',
                value: 'DEC',
              },
            },
          },
          day: { label: 'Day' },
          isoyear: { label: 'ISO Year' },
          isoweek: { label: 'ISO Week' },
          isoweekday: {
            label: 'ISO Weekday',
            text: 'Text',
            names: {
              monday: {
                label: 'Monday',
                value: 'Mo',
              },
              tuesday: {
                label: 'Tuesday',
                value: 'Tu',
              },
              wednesday: {
                label: 'Wednesday',
                value: 'We',
              },
              thursday: {
                label: 'Thursday',
                value: 'Th',
              },
              friday: {
                label: 'Friday',
                value: 'Fr',
              },
              saturday: {
                label: 'Saturday',
                value: 'Sa',
              },
              sunday: {
                label: 'Sunday',
                value: 'Su',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alphabet',
        digits: 'Number',
        expand: 'Adds up',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Other, like:',
        },
      },
      anything: {
        label: 'Anything',
      },
    },
  },
  performanceResetModal: {
    title: 'Rework',
    reason: 'Reason',
    resetButton: 'Confirm rework',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'No text added',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'No title',
      },
    },
  },
  printerSetup: {
    connectionError: 'Error when pairing the printer ',
    connectionNameError: 'Unknown printer name',
    connectionPdfError: 'Error when pairing the label',
    title: 'Printer Setup',
    installQzTray: {
      beforeLink: 'Install ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Download ',
      link: 'this file',
      beforeLocation: ' and save it as ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Add the next line to the end of the file ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Printer test:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra/Toshiba',
      },
    },
    printerModal: {
      title: 'Test Print',
      selectPrinter: 'Select Printer',
      noPrintersAvailable: 'No Printers Available',
      printButton: 'Print',
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Mo' },
      tuesday: { label: 'Tu' },
      wednesday: { label: 'We' },
      thursday: { label: 'Th' },
      friday: { label: 'Fr' },
      saturday: { label: 'Sa' },
      sunday: { label: 'Su' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Week' },
        month: { label: 'Month' },
      },
    },
    slot: {
      planned: 'Planned',
      overtime: 'Overtime',
      absent: 'Absent',
    },
    leaveSlotModal: {
      title: {
        add: 'Add Leave Request',
        edit: 'Change Leave Request',
      },
    },
    workTimesModal: {
      title: 'Work Times {{scope}}',
    },
    total: {
      planned: '{{time}} planned',
      actual: '{{time}} worked',
      overtime: '{{time}} of overtime',
      absence: '{{time}} of absence',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Period' },
      fullDay: { label: 'Full Day' },
      name: { label: 'Reason' },
      type: {
        label: 'Type',
        value: {
          vacation: 'Vacation',
          rwh: 'RWH',
          special: 'Special',
        },
        valueShort: {
          vacation: 'VAC',
          rwh: 'RWH',
          special: 'SPE',
        },
        valueMinutes: {
          vacation: 'Vacation',
          rwh: 'RWH',
          special: 'Special Leave',
        },
      },
      status: {
        label: 'Status',
        value: {
          approved: 'Approved',
          pending: 'Pending',
          rejected: 'Rejected',
        },
      },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
    },
    overview: {
      title: 'Leave Requests',
      noneFound: 'No leave requests have been found.',
      noneSelected: 'No leave request has been selected yet.',
      hasComments: 'Has comments',
      availabilityHeader: 'Available Operator Hours',
      statusAction: {
        approved: 'Approve',
        pending: 'Reconsider',
        rejected: 'Reject',
      },
    },
    edit: {
      comments: {
        empty: 'No comments have been placed yet.',
      },
      stats: {
        allowed: 'Allowed',
        used: 'Used',
        available: 'Available',
        requested: 'Requested',
      },
      days: {
        legend: {
          full: 'Full Availability',
          approved: 'When Approved',
          rejected: 'When Rejected',
        },
      },
      legal: 'Legal',
      extra: 'Extra',
    },
  },
  leaveCalendar: {
    overview: {
      title: 'Leave calendars',
      empty: 'No leave calendars could be found.',
      addButton: 'Leave calendar',
    },
    create: {
      title: 'Create leave calendar',
    },
    edit: {
      title: 'Edit leave calendar',
      saveBeforeLeaveSlot: 'You must first save this leave calendar before you can add leave.',
    },
    field: {
      name: { label: 'Name' },
      operators: { label: 'Operators' },
      users: { label: 'Users' },
      leaveSlots: { label: 'Leave' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Level',
        value: {
          1: 'Not qualified',
          2: 'Trainee',
          3: 'Qualified',
          4: 'Expert',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Processes',
      addButton: 'Process',
      empty: 'No processes could be found',
    },
    edit: {
      newBatch: {
        label: 'New Batch',
        info: 'Performing this step will create a new batch, these fields specify some details about this batch.',
      },
      versioned: {
        label: '(versioned)',
        info: 'This value only applies to the currently selected version.',
      },
      baseFields: {
        label: 'Basic fields',
        info: 'These values do not pair with a specific version but to all versions.',
      },
      batchSize: {
        label: 'Batch Size',
        value: {
          productionRequest: 'Order Size',
          unit: 'Unit',
          batch: 'Batch of {{count}}',
        },
        error: {
          notSmaller: 'The batch size should be smaller then the previous batch size.',
          notFinal: 'Batch size must be equal to {{expected}}.',
        },
      },
      title: 'Edit Process',
      version: 'Version {{version}}',
      finalized: {
        label: 'Finalized at: {{date}}',
        by: 'Finalized by: {{user}}',
      },
      updated: {
        label: 'Updated at: {{date}}',
        by: 'Updated by: {{user}}',
      },
      noUpdateInfo: 'No information available when this version has been updated.',
      draft: 'Concept',
      finalizeButton: 'Finalise',
      nextVersionButton: 'Next version',
      noStepSelected: 'No step selected',
      noBatchTypeSelected: 'No process selected',
      workStationsModal: {
        title: 'Select work stations',
      },
      tabs: {
        steps: 'Steps',
        instructions: 'Instructions',
        articleTypes: 'Article Types',
      },
      addArticleTypeModal: {
        title: 'Add article types',
        addButton: 'Add ({{count}})',
      },
      harvestDay: {
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
        sunday: 'Su',
      },
    },
    create: {
      title: 'Create Process',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Latest version' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Production Area' },
      batchType: { label: 'Process' },
      version: { label: 'Version' },
      newest: { label: 'Latest' },
      factory: { label: 'Factory' },
    },
  },
  dataSource: {
    edit: {
      title: 'Edit data source',
    },
    create: {
      title: 'Create data source',
    },
    overview: {
      title: 'Data sources',
      empty: 'No data sources are linked yet.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identification Code' },
      name: { label: 'Name' },
      deviceName: { label: 'Name of Source' },
      description: { label: 'Description' },
      workStations: { label: 'Paired Work Stations' },
      data: {
        label: 'Data',
        name: 'Name',
        type: 'Type',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Released',
          in_progress: 'In progress',
          finalized: 'Done',
          complete: 'Finalised',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Loading carriers',
      empty: 'No loading carriers that could be found.',
      addButton: 'Loading carrier',
      batchAddButton: 'Batch Add',
    },
    create: {
      title: 'Add Loading Carrier',
    },
    edit: {
      title: 'Edit Loading Carrier',
      weighing: '{{weight}}{{unit}}, weighed by {{user}} at {{time}}.',
      unknownUser: 'unknown',
    },
    batchAdd: {
      title: 'Batch Add Loading Carriers',
      quantity: 'Quantity',
      serialNumberFormat: 'Serial Number Format',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          pallet_euro: 'Euro pallet',
          pallet_pool: 'Pool pallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Box',
          stackbox: 'Stackable box',
        },
      },
      serialNumber: { label: 'Serial Number' },
      name: { label: 'Name' },
      description: { label: 'Description' },
      weight: { label: 'Weight' },
      width: { label: 'Width' },
      height: { label: 'Height' },
      length: { label: 'Length' },
      volume: { label: 'Volume' },
      picture: { label: 'Picture' },
      batch: { label: 'Batch' },
      hasBatch: { label: 'Has Batch' },
      weighings: { label: 'Weighings' },
      reweighDays: {
        label: 'Weighing Interval',
        info: 'Here you can specify how often the loading carrier needs to be weighed. In the loading carrier overview you can then see when the loading carrier needs to be weighed again and filter on loading carriers that needs to be weighed.',
        suffix: ' days',
      },
      reweighDate: { label: 'Reweigh Date' },
      needsReweighing: { label: 'Needs Reweighing' },
    },
  },
  login: {
    workstation: 'Workstation Code',
    barcode: 'Article barcode',
    title: 'Tracy Picker',
    username: 'Username',
    batchcode: 'Batchcode',
    notAllowed: 'This is not a correct workstation code',
    password: 'Password',
    forgotPassword: 'Forgot your password?',
    clearusername: 'Clear username',
    clearworkstation: 'Erase workstation code',
    clearprinter: 'Erase printer pairing info',
    forgotMailSend: 'Send email to reset password',
    successful: 'Succesfully logged in with badge ID',
    logIn: 'Log In',
    error: 'Error while logging in',
    wrong_credentials: 'Unrecognized batchcode',
  },
  shipping: {
    picture: 'Picture of the cargo',
    picture_accept: 'Accept picture',
    picture_decline: 'Re-take',
    packed: 'Finish packing article',
    start_send: 'Start shipping process',
    start_receive: 'Start receiving process',
    send_shipment: 'Send this shipment',
    receive_shipment: 'Accept receipt',
  },
  articleInfo: {
    clearBarcode: 'Erase barcode',
    search: 'Search for barcode',
    wrongCode: 'Barcode not available',
    notAllowed: 'This user is not allowed to have access to this info',
    error: 'Error while searching',
  },
  shipment: {
    articleInfo: {
      performanceLine:
        '{{stepLabel}} of process {{productionLineName}} performed @ {{performanceTime}} by {{performanceBy}}',
      batchType: 'Batch Type',
      batchDescription: 'Batch description',
      batchSize: 'Batch size',
      articleNameAndCode: 'Article name & code',
      scannedBarcode: 'Scanned barcode',
    },
    picker: {
      default: 'Select a value',
    },
    shipmentInfo: 'Shipment information',
    status: {
      picking: 'Picking',
      printing: 'Printing',
      sending: 'Sending',
      sent: 'Shipped',
      toReceive: 'To receive',
      received: 'Received and processed',
    },
    field: {
      salesOrder: {
        label: 'Sales Order',
      },
      purchaseOrder: { label: 'Purchase Order' },
      receiveDate: { label: 'Planned receipt date' },
      receivedTime: { label: 'Receipt time' },
      shippingDate: {
        label: 'Shipping date',
      },
      freightType: {
        label: 'Typ of cargo',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'Freight company',
      },
      shippingName: {
        label: 'Driver',
      },
      shippingLicense: {
        label: 'Licence plate',
      },
      shippingTime: {
        label: 'Time of shipment',
      },
      quantity: {
        label: 'Quantity',
      },
      batchSize: {
        label: 'Batch size',
      },
    },
    outbound: {
      pickitem: 'Pick items',
      printreceipt: 'Print receipt note',
      package: 'Packing',
      printshipmentlabel: 'Print shipment label',
      send: 'Ship',
    },
    inbound: {
      receiveItem: 'Receive order line',
      barcodeCreation: 'Identify products',
      warehousePlacement: 'Add to stock location',
    },
    heading: {
      cmrLines: 'CMR LINES',
    },
    titleInbound: 'Inbound shipment {{id}}',
    titleOutbound: 'Outbound shipment {{id}}',
    confirmShipment: 'Weet je zeker dat je {{custName}} een zending wilt sturen met trackcode {{trackingNumber}}?',
    confirmReceive:
      'Weet je zeker dat je van {{custName}} een zending wilt accepteren met trackcode {{trackingNumber}}?',
    sendShipmentTitle: 'Send',
    receiveShipmentTitle: 'Receive',
    scan: 'Scan',
    leftToPick: 'To Pick',
    actualyPicked: 'Picked',
    documents: {
      label: 'Documents',
      packingListRequired: 'Packing List',
      invoiceRequired: 'Invoice',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Articles',
      info: 'Info',
    },
    noCmrLines: 'This shipment does not have any CMR Lines.',
    box: 'Box {{id}}',
    buyBox: 'Box',
    hiddenBoxes: '{{count}} boxes',
    error: {
      wrongArticle: 'Wrong article.',
      alreadyScanned: 'Box already scanned.',
      alreadyShipped: 'Box already included in another pickorder.',
      tooManyProducts: 'Box contains too many products.',
      invalidQuantity: 'Invalid value for quantity.',
    },
  },
  common: {
    article: {
      field: {
        code: { label: 'Code' },
        name: { label: 'Name' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Quantity' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Sales Order' },
        purchaseOrder: { label: 'Purchase Order' },
        shippingDate: { label: 'Shipping Date' },
        receiveDate: { label: 'Planned delivery date' },
        shippingName: { label: 'Shipper name' },
        shippingTime: { label: 'Shipped time' },
        receivedTime: { label: 'Delivery time' },
        shippingLicense: { label: 'Shipper license plate' },
        freight: {
          label: 'Freight',
          value: {
            road: 'Road',
            air: 'Air',
            sea: 'Sea',
            parcel: 'Parcel',
          },
        },
        freightCompany: { label: 'Freight Company' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Mark' },
        number: { label: 'Number' },
        method: { label: 'Method' },
        nature: { label: 'Nature' },
        weight: { label: 'Weight' },
        cubage: { label: 'Cubage' },
      },
    },
    loading: 'Loading...',
    error: {
      somethingWentWrong: 'Something went wrong.',
    },
  },
  workTime: {
    field: {
      date: { label: 'Date' },
      startTime: { label: 'Start Time' },
      endTime: { label: 'End Time' },
      automaticallyClockedOut: { label: 'Clocked-out automatically' },
    },
  },
  softdeleted: {
    label: 'Show deleted items',
    value: {
      true: 'yes',
      false: 'no',
      only: 'only',
    },
  },
  logistics: {
    erpCounts: {
      header: {
        date: 'Date',
        quantity: '#',
      },
    },
  },
  extraLeaveBalance: {
    field: {
      year: { label: 'Applicable to (year)' },
      legalLeaveMinutes: { label: 'Legal' },
      extraLeaveMinutes: { label: 'Extra' },
      rwhMinutes: { label: 'RWH' },
    },
  },
  formField: {
    field: {
      slug: { label: 'Print slug' },
      articleType: {
        self: '(Dynamic)',
        selfInfo: "This will automatically fill in the Article Type where you're using this template.",
      },
      type: {
        quantityInfo: 'This type can be used to set a precise quantity on a variable batch. You can also enter a weight per unit, in this case the quantity will be based on a weight that the operator enters.',
      },
      measureMin: { label: 'Minimum' },
      measureMax: { label: 'Maximum' },
      quantityUnitWeight: { label: 'Weight per Unit' },
      articleTypeBatchSize: { label: 'Batch size?' },
      required: { label: 'Required' },
    },
  },
  integration: {
    overview: {
      title: 'Integrations',
      empty: 'No integrations found.',
      clearSyncErrorButton: 'Clear sync errors',
      addButton: 'Integration',
      inactive: 'This integration is not active.',
    },
    create: {
      title: 'Add Integration',
    },
    edit: {
      title: 'Edit Integration',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          exact: 'Exact',
          unit4: 'Unit 4',
          navision: 'Navision'
        },
      },
      name: { label: 'Name' },
      syncErrorCount: { label: 'Sync Errors' },
    },
    syncmodal: {
      title: 'Synchronize',
      syncButton: 'Sync',
      pending: 'Pending',
      from_db: 'Cached',
      from_inline: 'Partial',
      created: 'Created',
      updated: 'Updated',
      requested: 'Requested',
      dependencies: 'Dependencies',
      measureMin: { label: 'Min' },
      measureMax: { label: 'Max' },
      quantityUnitWeight: { label: 'Unit Weight' },
    },
    syncers: {
      exact_account_syncer: 'Accounts',
      exact_address_syncer: 'Addresses',
      exact_item_syncer: 'Items',
      exact_project_syncer: 'Projects',
      exact_purchase_order_syncer: 'Purchase orders',
      exact_sales_order_syncer: 'Sales orders',
      exact_warehouse_syncer: 'Warehouses',
      exact_storage_location_syncer: 'Storage Locations',
      exact_item_warehouse_syncer: 'Stocks (per warehouse)',
      exact_item_warehouse_location_syncer: 'Stocks (per location)',
      exact_goods_receipt_syncer: 'Goods Receipt',
      exact_warehouse_transfer_syncer: 'Warehouse transfers',
      exact_stock_position_syncer: 'Stock updates',
      exact_shipping_method_syncer: 'Shipping Methods',
      exact_document_syncer: 'Documents',
    },
  },
  exactIntegration: {
    overview: {
      authButton: 'Activate Exact Integration',
      authResult: {
        ok: 'Exact integration succesfully activated.',
        error: {
          invalid_request: 'Invalid request sent to Exact.',
          invalid_client: 'Invalid client sent to Exact.',
          invalid_grant: 'Invalid grant sent to Exact.',
          invalid_scope: 'Invalid scope sent to Exact. ',
          unauthorized_client: 'Unauthorized client sent to Exact.',
          unsupported_grant_type: 'Unsupported grant type sent to Exact.',
        },
      },
      syncButton: 'Sync with Exact',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact failed.' },
        success: {
          notification: 'Succesfully synced with Exact',
          count: {
            address: {
              tracy: {
                created: 'Created {{count}} address in Tracy.',
                created_plural: 'Created {{count}} addresses in Tracy.',
                updated: 'Updated {{count}} address in Tracy.',
                updated_plural: 'Updated {{count}} addresses in Tracy.',
              },
              exact: {
                created: 'Created {{count}} address in Exact.',
                created_plural: 'Created {{count}} addresses in Exact.',
                updated: 'Updated {{count}} address in Exact.',
                updated_plural: 'Updated {{count}} addresses in Exact.',
              },
            },
            item: {
              tracy: {
                created: 'Created {{count}} item in Tracy.',
                created_plural: 'Created {{count}} items in Tracy.',
                updated: 'Updated {{count}} item in Tracy.',
                updated_plural: 'Updated {{count}} items in Tracy.',
              },
              exact: {
                created: 'Created {{count}} item in Exact.',
                created_plural: 'Created {{count}} items in Exact.',
                updated: 'Updated {{count}} item in Exact.',
                updated_plural: 'Updated {{count}} items in Exact.',
              },
            },
            project: {
              tracy: {
                created: 'Created {{count}} project in Tracy.',
                created_plural: 'Created {{count}} projects in Tracy.',
                updated: 'Updated {{count}} project in Tracy.',
                updated_plural: 'Updated {{count}} projects in Tracy.',
              },
              exact: {
                created: 'Created {{count}} project in Exact.',
                created_plural: 'Created {{count}} projects in Exact.',
                updated: 'Updated {{count}} project in Exact.',
                updated_plural: 'Updated {{count}} projects in Exact.',
              },
            },
            storage_location_stock_position: {
              tracy: {
                created: 'Created {{count}} storage location stock position in Tracy.',
                created_plural: 'Created {{count}} storage location stock positions in Tracy.',
                updated: 'Updated {{count}} storage location stock position in Tracy.',
                updated_plural: 'Updated {{count}} storage location stock positions in Tracy.',
              },
              exact: {
                created: 'Created {{count}} storage location stock position in Exact.',
                created_plural: 'Created {{count}} storage location stock positions in Exact.',
                updated: 'Updated {{count}} storage location stock position in Exact.',
                updated_plural: 'Updated {{count}} storage location stock positions in Exact.',
              },
            },
            bill_of_material: {
              tracy: {
                created: 'Created {{count}} bill of material in Tracy.',
                created_plural: 'Created {{count}} bill of materials in Tracy.',
                updated: 'Updated {{count}} bill of material in Tracy.',
                updated_plural: 'Updated {{count}} bill of materials in Tracy.',
              },
              exact: {
                created: 'Created {{count}} bill of material in Exact.',
                created_plural: 'Created {{count}} bill of materials in Exact.',
                updated: 'Updated {{count}} bill of material in Exact.',
                updated_plural: 'Updated {{count}} bill of materials in Exact.',
              },
            },
            bill_of_material_item: {
              tracy: {
                created: 'Created {{count}} bill of material item in Tracy.',
                created_plural: 'Created {{count}} bill of material items in Tracy.',
                updated: 'Updated {{count}} bill of material item in Tracy.',
                updated_plural: 'Updated {{count}} bill of material items in Tracy.',
              },
              exact: {
                created: 'Created {{count}} bill of material item in Exact.',
                created_plural: 'Created {{count}} bill of material items in Exact.',
                updated: 'Updated {{count}} bill of material item in Exact.',
                updated_plural: 'Updated {{count}} bill of material items in Exact.',
              },
            },
            account: {
              tracy: {
                created: 'Created {{count}} account in Tracy.',
                created_plural: 'Created {{count}} accounts in Tracy.',
                updated: 'Updated {{count}} account in Tracy.',
                updated_plural: 'Updated {{count}} accounts in Tracy.',
              },
              exact: {
                created: 'Created {{count}} account in Exact.',
                created_plural: 'Created {{count}} accounts in Exact.',
                updated: 'Updated {{count}} account in Exact.',
                updated_plural: 'Updated {{count}} accounts in Exact.',
              },
            },
            sales_order: {
              tracy: {
                created: 'Created {{count}} sales order in Tracy.',
                created_plural: 'Created {{count}} sales orders in Tracy.',
                updated: 'Updated {{count}} sales order in Tracy.',
                updated_plural: 'Updated {{count}} sales orders in Tracy.',
              },
              exact: {
                created: 'Created {{count}} sales order in Exact.',
                created_plural: 'Created {{count}} sales orders in Exact.',
                updated: 'Updated {{count}} sales order in Exact.',
                updated_plural: 'Updated {{count}} sales orders in Exact.',
              },
            },
            purchase_order: {
              tracy: {
                created: 'Created {{count}} purchase order in Tracy.',
                created_plural: 'Created {{count}} purchase orders in Tracy.',
                updated: 'Updated {{count}} purchase order in Tracy.',
                updated_plural: 'Updated {{count}} purchase orders in Tracy.',
              },
              exact: {
                created: 'Created {{count}} purchase order in Exact.',
                created_plural: 'Created {{count}} purchase orders in Exact.',
                updated: 'Updated {{count}} purchase order in Exact.',
                updated_plural: 'Updated {{count}} purchase orders in Exact.',
              },
            },
            shop_order: {
              tracy: {
                created: 'Created {{count}} production order in Tracy.',
                created_plural: 'Created {{count}} production orders in Tracy.',
                updated: 'Updated {{count}} production order in Tracy.',
                updated_plural: 'Updated {{count}} production orders in Tracy.',
              },
              exact: {
                created: 'Created {{count}} production order in Exact.',
                created_plural: 'Created {{count}} production orders in Exact.',
                updated: 'Updated {{count}} production order in Exact.',
                updated_plural: 'Updated {{count}} production orders in Exact.',
              },
            },
            shop_order_material_plan: {
              tracy: {
                created: 'Created {{count}} production order material plan in Tracy.',
                created_plural: 'Created {{count}} production order material plans in Tracy.',
                updated: 'Updated {{count}} production order material plan in Tracy.',
                updated_plural: 'Updated {{count}} production order material plans in Tracy.',
              },
              exact: {
                created: 'Created {{count}} production order material plan in Exact.',
                created_plural: 'Created {{count}} production order material plans in Exact.',
                updated: 'Updated {{count}} production order material plan in Exact.',
                updated_plural: 'Updated {{count}} production order material plans in Exact.',
              },
            },
            shop_order_receipt: {
              tracy: {
                created: 'Created {{count}} production order receipt in Tracy.',
                created_plural: 'Created {{count}} production order receipts in Tracy.',
                updated: 'Updated {{count}} production order receipt in Tracy.',
                updated_plural: 'Updated {{count}} production order receipts in Tracy.',
              },
              exact: {
                created: 'Created {{count}} production order receipt in Exact.',
                created_plural: 'Created {{count}} production order receipts in Exact.',
                updated: 'Updated {{count}} production order receipt in Exact.',
                updated_plural: 'Updated {{count}} production order receipts in Exact.',
              },
            },
            shop_order_reversal: {
              tracy: {
                created: 'Created {{count}} production order reversal in Tracy.',
                created_plural: 'Created {{count}} production order reversals in Tracy.',
                updated: 'Updated {{count}} production order reversal in Tracy.',
                updated_plural: 'Updated {{count}} production order reversals in Tracy.',
              },
              exact: {
                created: 'Created {{count}} production order reversal in Exact.',
                created_plural: 'Created {{count}} production order reversals in Exact.',
                updated: 'Updated {{count}} production order reversal in Exact.',
                updated_plural: 'Updated {{count}} production order reversals in Exact.',
              },
            },
            opportunity: {
              tracy: {
                created: 'Created {{count}} opportunity in Tracy.',
                created_plural: 'Created {{count}} opportunities in Tracy.',
                updated: 'Updated {{count}} opportunity in Tracy.',
                updated_plural: 'Updated {{count}} opportunities in Tracy.',
              },
              exact: {
                created: 'Created {{count}} opportunity in Exact.',
                created_plural: 'Created {{count}} opportunities in Exact.',
                updated: 'Updated {{count}} opportunity in Exact.',
                updated_plural: 'Updated {{count}} opportunities in Exact.',
              },
            },
            quotation: {
              tracy: {
                created: 'Created {{count}} quotation in Tracy.',
                created_plural: 'Created {{count}} quotations in Tracy.',
                updated: 'Updated {{count}} quotation in Tracy.',
                updated_plural: 'Updated {{count}} quotations in Tracy.',
              },
              exact: {
                created: 'Created {{count}} quotation in Exact.',
                created_plural: 'Created {{count}} quotations in Exact.',
                updated: 'Updated {{count}} quotation in Exact.',
                updated_plural: 'Updated {{count}} quotations in Exact.',
              },
            },
            goods_receipt: {
              tracy: {
                created: 'Created {{count}} goods receipt in Tracy.',
                created_plural: 'Created {{count}} goods receipts in Tracy.',
                updated: 'Updated {{count}} goods receipt in Tracy.',
                updated_plural: 'Updated {{count}} goods receipts in Tracy.',
              },
              exact: {
                created: 'Created {{count}} goods receipt in Exact.',
                created_plural: 'Created {{count}} goods receipts in Exact.',
                updated: 'Updated {{count}} goods receipt in Exact.',
                updated_plural: 'Updated {{count}} goods receipts in Exact.',
              },
            },
            goods_delivery: {
              tracy: {
                created: 'Created {{count}} goods delivery in Tracy.',
                created_plural: 'Created {{count}} goods deliveries in Tracy.',
                updated: 'Updated {{count}} goods delivery in Tracy.',
                updated_plural: 'Updated {{count}} goods deliveries in Tracy.',
              },
              exact: {
                created: 'Created {{count}} goods delivery in Exact.',
                created_plural: 'Created {{count}} goods deliveries in Exact.',
                updated: 'Updated {{count}} goods delivery in Exact.',
                updated_plural: 'Updated {{count}} goods deliveries in Exact.',
              },
            },
            sales_return: {
              tracy: {
                created: 'Created {{count}} sales return in Tracy.',
                created_plural: 'Created {{count}} sales returns in Tracy.',
                updated: 'Updated {{count}} sales return in Tracy.',
                updated_plural: 'Updated {{count}} sales returns in Tracy.',
              },
              exact: {
                created: 'Created {{count}} sales return in Exact.',
                created_plural: 'Created {{count}} sales returns in Exact.',
                updated: 'Updated {{count}} sales return in Exact.',
                updated_plural: 'Updated {{count}} sales returns in Exact.',
              },
            },
            warehouse_transfer: {
              tracy: {
                created: 'Created {{count}} warehouse transfer in Tracy.',
                created_plural: 'Created {{count}} warehouse transfers in Tracy.',
                updated: 'Updated {{count}} warehouse transfer in Tracy.',
                updated_plural: 'Updated {{count}} warehouse transfers in Tracy.',
              },
              exact: {
                created: 'Created {{count}} warehouse transfer in Exact.',
                created_plural: 'Created {{count}} warehouse transfers in Exact.',
                updated: 'Updated {{count}} warehouse transfer in Exact.',
                updated_plural: 'Updated {{count}} warehouse transfers in Exact.',
              },
            },
            stock_count: {
              exact: {
                created: 'Created {{count}} stock count in Exact.',
                created_plural: 'Created {{count}} stock counts in Exact.',
              },
            },
            shipping_method: {
              tracy: {
                created: 'Created {{count}} shipping method in Tracy.',
                created_plural: 'Created {{count}} shipping methods in Tracy.',
                updated: 'Updated {{count}} shipping method in Tracy.',
                updated_plural: 'Updated {{count}} shipping methods in Tracy.',
              },
              exact: {
                created: 'Created {{count}} shipping method in Exact.',
                created_plural: 'Created {{count}} shipping methods in Exact.',
                updated: 'Updated {{count}} shipping method in Exact.',
                updated_plural: 'Updated {{count}} shipping methods in Exact.',
              },
            },
            document: {
              exact: {
                created: 'Created {{count}} document in Exact.',
                created_plural: 'Created {{count}} documents in Exact.',
                updated: 'Updated {{count}} document in Exact.',
                updated_plural: 'Updated {{count}} documents in Exact.',
              },
            },
          },
        },
      },
    },
    field: {
      division: { label: 'Division' },
      webhooks: { label: 'Webhooks' },
      address: { label: 'Address' },
      item: { label: 'Item' },
      manageEndpointsHeader: { label: 'Manage Exact API Endpoints' },
      manageWebhooksHeader: { label: 'Manage Exact Webhooks Topics' },
      account: { label: 'Account' },
      opportunity: { label: 'Opportunity' },
      salesOrder: { label: 'Sales Order' },
      purchaseOrder: { label: 'Purchase Order' },
      project: { label: 'Project' },
      billOfMaterial: { label: 'Bill Of Material' },
      billOfMaterialItem: { label: 'Bill Of Material Item' },
      shopOrder: { label: 'Shop Order' },
      shopOrderMaterialPlan: { label: 'Shop Order Material Plan' },
      shopOrderReceipt: { label: 'Shop Order Receipt' },
      shopOrderReversal: { label: 'Shop Order Reversal' },
      salesReturn: { label: 'Sales Return' },
      warehouse: { label: 'Warehouse' },
      storageLocationStockPosition: { label: 'Storage Location Stock Position' },
      itemWarehouse: { label: 'Item Warehouse' },
      warehouseTransfer: { label: 'Warehouse Transfer' },
      goodsReceipt: { label: 'Goods Receipt' },
      goodsDelivery: { label: 'Goods Delivery' },
      stockCount: { label: 'Stock Count' },
      stockCountReversal: { label: 'Stock Count Reversal' },
      stockTopic: { label: 'Stock' },
      stockSerialNumber: { label: 'Stock Serial Number' },
      shippingMethod: { label: 'Shipping Method' },
      salesOrderWebhookTopic: { label: 'Sales Order Webhook' },
      document: { label: 'Document' },
    },
    edit: {
      topics: {
        title: 'Manage Exact API Endpoints',
        subtitle: {
          webhooks: 'Webhooks',
          general: 'General',
          production: 'Production',
          warehouse: 'Warehouse',
        },
      },
    },
  },
  unit4Integration: {
    overview: {
      authButton: 'Activate Unit4 Integration',
      authResult: {
        ok: 'Unit4 integration succesfully activated.',
        error: {
          invalid_request: 'Invalid request sent to Unit4.',
          invalid_client: 'Invalid client sent to Unit4.',
          invalid_grant: 'Invalid grant sent to Unit4.',
          invalid_scope: 'Invalid scope sent to Unit4. ',
          unauthorized_client: 'Unauthorized client sent to Unit4.',
          unsupported_grant_type: 'Unsupported grant type sent to Unit4.',
        },
      },
      syncButton: 'Sync with Unit4',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact failed.' },
        success: {
          notification: 'Succesfully synced with Exact',
        },
      },
    },
    field: {
      administration: { label: 'Administration code' },
      baseUrl: { label: 'Unit 4 server base URL' },
      manageEndpointsHeader: { label: 'Manage Unit4 API-endpoints' },
      goodsReceipt: { label: 'Goods Receipt' },
      product: { label: 'Product' },
      productDetails: { label: 'Product Detail' },
      supplier: { label: 'Supplier' },
      purchaseOrder: { label: 'Purchase Order' },
      customer: { label: 'Customer' },
      order: { label: 'Order' },
    },
  },
  syncError: {
    overview: {
      title: 'Sync Errors',
      empty: 'No sync errors found.',
    },
    field: {
      id: { label: 'ID' },
      message: { label: 'Message' },
    },
  },
  project: {
    overview: {
      title: 'Projects',
      empty: 'No projects that could be found.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      description: { label: 'Description' },
      notes: { label: 'Notes' },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
    },
  },
  billOfMaterialVersion: {
    edit: {
      title: 'View Bill Of Material',
    },
    field: {
      version: {
        label: 'Version',
      },
      status: {
        label: 'Status',
      },
      description: {
        label: 'Description',
      },
      default: {
        label: 'Default',
      },
    },
  },
  billOfMaterialItem: {
    field: {
      articleType: { label: 'Article Type' },
      description: { label: 'Description' },
      quantity: { label: 'Quantity' },
      required: { label: '# required' },
      assigned: { label: '# allocated' },
      backflush: { label: 'Backflush' },
      number: { label: 'Line' },
      warehouse: { label: 'Warehouse' },
      storageLocations: { label: 'Storage locations' }
    },
  },
  productionOrder: {
    field: {
      billOfMaterialVersion: { label: 'BOM Version' },
      warehouse: { label: 'Warehouse' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Released',
          finished: 'Finished',
        },
      },
    },
    billOfMaterialVersionPlaceholder: 'No BOM available',
    procesPlaceholder: 'No make process',
    edit: {
      title: 'Edit Production Order',
    },
    create: {
      title: 'Create Production Order',
    },
  },
  script: {
    overview: {
      title: 'Scripts',
      empty: 'No scripts found.',
      addButton: 'Script',
    },
    create: {
      title: 'Add Script',
    },
    edit: {
      title: 'Edit Script',
      schedule: 'Day Schedule',
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied succesfully',
        pasted: 'Pasted succesfully',
        pasteError: 'Nothing to paste',
        pasteTypeMismatch: 'Clipboard contains script of a different type',
      },
    },
    field: {
      type: {
        label: 'Type',
        value: {
          environment: 'Environment',
          light: 'Light',
          nutrition: 'Nutrition',
          water: 'Water',
        },
      },
      name: { label: 'Name' },
      status: {
        label: 'Status',
        value: {
          concept: 'Concept',
          test: 'Test',
          production: 'Production',
          archived: 'Archived',
        },
      },
      notes: { label: 'Notes' },
    },
  },
  environmentScript: {
    field: {
      direction: {
        label: 'Direction',
        value: {
          a_to_b: 'A to B',
          b_to_a: 'B to A',
        },
      },
      speed: { label: 'Speed' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Time' },
    },
  },
  environmentScriptSchedule: {
    field: {
      direction: {
        label: 'Direction',
        value: {
          a_to_b: 'A to B',
          b_to_a: 'B to A',
        },
      },
      speed: { label: 'Speed' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Time' },
    },
  },
  waterScript: {
    field: {
      nutritionScript: { label: 'Nutrition' },
      ec: { label: 'EC' },
      ecMin: { label: 'EC min' },
      ecMax: { label: 'EC max' },
      ecT: { label: 'EC t' },
      ph: { label: 'pH' },
      phMin: { label: 'pH min' },
      phMax: { label: 'pH max' },
      phT: { label: 'pH t' },
      pulse: { label: 'Pulse' },
      cycle: { label: 'Cycle' },
      flowMin: { label: 'Flow min' },
      flowMax: { label: 'Flow max' },
    },
  },
  nutritionScript: {
    field: {
      nitrogen: { label: 'Nitrogen (N)' },
      phosphorus: { label: 'Phosphorus (P)' },
      potassium: { label: 'Potassium (K)' },
      calcium: { label: 'Calcium (Ca)' },
      magnesium: { label: 'Magnesium (Mg)' },
      sulphur: { label: 'Sulphur (S)' },
    },
  },
  lightScript: {
    field: {
      intensity: { label: 'Intensity' },
      startTime: { label: 'Time' },
    },
  },
  lightScriptSchedule: {
    field: {
      intensity: { label: 'Intensity' },
      startTime: { label: 'Time' },
    },
  },
  growStep: {
    field: {
      plantationLine: { label: 'Plantation Area' },
      days: { label: 'Days' },
      color: {
        label: 'Color',
        value: {
          blue: 'Blue',
          purple: 'Purple',
          red: 'Red',
          orange: 'Orange',
          green: 'Green',
        },
      },
      climateScript: { label: 'Climate Script' },
      lightScript: { label: 'Light Script' },
      waterScript: { label: 'Water Script' },
    },
  },
  rampUp: {
    overview: {
      title: 'Scenarios',
      empty: 'No scenarios found.',
      addButton: 'Scenario',
    },
    create: {
      title: 'Add Scenario',
    },
    edit: {
      title: 'Edit Scenario',
      schedule: 'Harvest Schedule',
      automation: 'Automation Dates',
      noLimit: 'No Limit',
      graph: {
        label: 'Graph',
        value: {
          capacity: 'Capacity',
          operators: 'Operators',
          harvest: 'Harvest',
        },
      },
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied succesfully',
        pasted: 'Pasted succesfully',
        pasteError: 'Nothing to paste',
      },
    },
    field: {
      name: { label: 'Name' },
      articles: {
        label: 'Articles',
        empty: 'No articles added yet',
        add: 'Add Article',
      },
      capacity: { label: 'Total Containers' },
      monday: { label: 'Monday' },
      tuesday: { label: 'Tuesday' },
      wednesday: { label: 'Wednesday' },
      thursday: { label: 'Thursday' },
      friday: { label: 'Friday' },
      saturday: { label: 'Saturday' },
      sunday: { label: 'Sunday' },
    },
  },
  rampUpTarget: {
    field: {
      date: { label: 'Date' },
      processes: { label: 'Processes' },
      capacity: { label: 'Max Capacity' },
      maxOperators: { label: 'Max Operators' },
      maxHarvest: { label: 'Max Daily Harvest' },
    },
  },
  rampUpAutomation: {
    field: {
      workStation: { label: 'Work Station' },
      date: { label: 'Automation Date' },
    },
  },
  workStationSession: {
    overview: {
      title: 'Work Station Sessions',
      empty: 'No work station sessions found.',
      viewPrintJobsButton: 'View print jobs',
    },
    field: {
      id: { label: 'ID' },
      type: {
        label: 'Type',
        value: {
          desktop: 'Desktop',
          mobile: 'Mobile',
        },
      },
      printers: {
        label: 'Printers',
        empty: 'No printers available.',
      },
      workStation: { label: 'Work Station' },
      appVersion: { label: 'App Version' },
      isActive: { label: 'Is Active' },
      createdAt: { label: 'Started At' },
      updatedAt: { label: 'Last Active At' },
      printJobs: { label: 'Print Jobs' },
    },
  },
  printJob: {
    field: {
      printer: {
        label: 'Printer',
      },
      status: {
        label: 'Status',
        value: {
          todo: 'Todo',
          done: 'Done',
          failed: 'Failed',
        },
      },
      content: {
        label: 'Content',
      },
      copies: {
        label: 'Copies',
      },
      createdAt: {
        label: 'Created At',
      },
    }
  },
  operatorAssignment: {
    field: {
      workStation: { label: 'Work Station' },
      operator: { label: 'Operator' },
      startAt: { label: 'Start At' },
      endAt: { label: 'End At' },
    },
  },
  warehouseTransfer: {
    overview: {
      title: 'Warehouse transfers',
      empty: 'No warehouse transfers.',
      viewLinesButton: 'View warehouse transfer lines',
    },
    edit: {
      title: 'Edit Warehouse Transfer',
    },
    create: {
      title: 'Add Warehouse transfer',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      entryDate: { label: 'Entry Date' },
      plannedDeliveryDate: { label: 'Planned Transfer Date' },
      completedOnDate: { label: 'Completed On Date' },
      status: {
        label: 'Status',
        value: {
          draft: 'Draft',
          processed: 'Processed',
        },
      },
      source: {
        label: 'Source',
        value: {
          manual_entry: 'Manual entry',
          import: 'Import',
          transfer_advice: 'Transfer advice',
          web_service: 'Web service',
        },
      },
      warehouseFrom: {
        label: 'Warehouse From',
      },
      warehouseTo: {
        label: 'Warehouse To',
      },
      type: {
        label: 'Transfer type',
        value: {
          true: 'Location',
          false: 'Warehouse',
        }
      },
      productionRequest: {
        label: 'Production request',
        isNull: 'Null work orders',
      },
      transferLines: {
        label: 'Warehouse Transfer Lines',
        value: 'Transfer Line {{id}}',
        empty: 'No Warehouse transfer lines were added.',
      },
    },
  },
  warehouseTransferLine: {
    empty: 'No warehouse transfer lines.',
    create: {
      title: 'View Warehouse transfer lines',
    },
    field: {
      id: { label: 'ID' },
      lineNumber: { label: 'Line Number' },
      createdDate: { label: 'Created Date' },
      quantity: { label: 'Quantity' },
      articleType: { label: 'Article Type' },
      availableStock: { label: 'Available stock' },
      noAvailableStock: { label: 'No stock availabe for the selected article type.' },
      storageLocation: {
        id: { label: 'ID' },
        warehouse: { label: 'Warehouse' },
        code: { label: 'Code' },
        stock: { label: 'Stock' },
      },
      storageLocationFrom: {
        label: 'From Storage Location',
      },
      storageLocationTo: {
        label: 'To Storage Location',
      },
      articleType: {
        label: 'Article Type',
      },
      warehouseTransfer: {
        label: 'Transfer ID',
      },
    },
  },
  detailImageModal: {
    title: { label: 'Uploaded images' },
    popup: { label: 'View uploaded images' }
  },
  stockCount: {
    field: {
      warehouse: { label: 'Warehouse' },
      defaultStorageLocation: { label: 'Storage Location' },
    },
    edit: {
      title: 'Edit Stock Count'
    },
    title: { label: 'Uploaded images' },
    popup: { label: 'View uploaded images' },
    missingStockCountProcess: 'Create missing Stock Count process',
  },
  factory: {
    overview: {
      title: 'Factories',
      empty: 'No factories found',
      addButton: 'Factory',
    },
    create: {
      title: 'Add Factory',
    },
    edit: {
      title: 'Edit Factory',
    },
    field: {
      name: { label: 'Name' },
      timezone: { label: 'Location' },
      currentTime: { label: 'Current Time' },
    },
  },
  plantationAllocation: {
    field: {
      startDate: { label: 'Start' },
      endDate: { label: 'End' },
    },
  },
  blueSkiesOverride: {
    articleType: {
      field: {
        code: { label: 'SKU' },
      },
    },
  },
  articleTypeStorageLocation: {
    field: {
      isDefault: { label: 'Default' },
      stock: { label: '# available' },
      assigned: { label: '# allocated' },
      warehouse: { label: 'Warehouse' },
    },
  },
  storageLocation: {
    field: {
      code: { label: 'Code' },
      name: { label: 'Name' },
    },
  },
  resourceAllocation: {
    field: {
      source: { label: 'Source' },
      productionOrder: { label: 'Production Order' },
      supplier: { label: 'Supplier' },
      reference: { label: 'Reference' },
      expectedDate: { label: 'Expected Date' },
      otherSalesOrders: { label: 'Other Sales Orders' },
      quantity: { label: 'Quantity' },
    },
  },
  navisionSalesOrder: {
    field: {
      orderDate: { label: 'Order Date' },
      requestedDeliveryDate: { label: 'Requested' },
      promisedDeliveryDate: { label: 'Promised' },
      lastDatetimeModified: { label: 'Last Modified' },
      deliveryAddress: { label: 'Address Line 1' },
      deliveryAddress2: { label: 'Address Line 2' },
      deliveryCity: { label: 'City' },
      deliveryPostcode: { label: 'Postal Code' },
      deliveryCountryRegionCode: { label: 'Region' },
      sellToAddress: { label: 'Address Line 1' },
      sellToAddress2: { label: 'Address Line 2' },
      sellToCity: { label: 'City' },
      sellToPostcode: { label: 'Postal Code' },
    },
  },
  businessRelation: {
    field: {
      name: { label: 'Customer Name' },
    },
  },
  unit4Customer: {
    field: {
      customerId: { label: 'Customer ID'}
    }
  },
  navisionCustomer: {
    field: {
      customerId: { label: 'Customer ID' },
    },
  },
  unit4Order : {
    field: {
      orderDate: { label: 'Delivery date'}
    }
  },
  salesOrderResponsibility: {
    field: {
      name: { label: 'Name' },
      company: { label: 'Company' },
      role: { label: 'Role' },
      email: { label: 'Email' },
      phoneNumber: { label: 'Phone Number' },
    },
  },
  exactAccount: {
    field: {
      number: { label: 'Exact Number' },
    },
  },
  exactSalesOrder: {
    field: {
      deliverAt: { label: 'Deliver At' },
      orderedAt: { label: 'Ordered At' },
      modified: { label: 'Modified At' },
    },
  },
  exactAddress: {
    field: {
      addressLine1: { label: 'Address Line 1' },
      addressLine2: { label: 'Address Line 2' },
      addressLine3: { label: 'Address Line 3' },
      city: { label: 'City' },
      postcode: { label: 'Postal Code' },
      countryName: { label: 'Country' },
    },
  },
  printModal: {
    title: 'Print {{item}}',
    selectPrinter: 'Select Printer',
    noPrintersAvailable: 'No Printers Available',
    printButton: 'Print',
    copies: 'Number of copies',
  },
}
